import React, { Component } from 'react';


class Footer extends Component {
render() {
    return (
    <div className="footer-container">
        <h1 id="contact">Copyright© 2020 Activity Drive. All Rights Reserved</h1>
    </div>
    );
  }
}

export default Footer;