import React, { Component } from 'react';


class Contact extends Component {
render() {
    return (
    <div className="contact-container">
        <h1 id="contact">Contact me at mikex2394@gmail.com</h1>
    </div>
    );
  }
}

export default Contact;