import React, { Component } from 'react';


class Button extends Component {

render() {
    return (
    <div>
         <h1 className="search">Click button to find an activity below
        </h1>
       
          <button className="btn"  onClick ={this.props.getName}>
            Search
          </button>
          
    </div>
    );
  }
}

export default Button;