import React, { Component } from 'react';


class About extends Component {
render() {
    return (
    <div>
        <h1 className="about">Cure your boredom by finding a fun activity to do
        </h1>
    </div>
    );
  }
}

export default About;