import React, { Component } from 'react';


class Result extends Component {
render() {
    return (
    <div className="results-container">
      
     { this.props.displayResults() }

    </div>
    );
  }
}

export default Result;