import React from 'react';
import './App.css';
import Button from './components/Button'
import About from './components/About'
import Result from './components/Result'
import Contact from './components/Contact'
import Footer from './components/Footer'



class App extends React.Component {
    constructor() {
      super()
      this.state = {
        apiCall: null,
      }
      this.getName = this.getName.bind(this)
      this.displayResults = this.displayResults.bind(this)  
  }

   getName =  (e) => {
    e.preventDefault();

    const firstCall =  fetch(`https://www.boredapi.com/api/activity/`)
      
     firstCall.then( response => response.json())
             .then(data => {
                this.setState({
                     apiCall:data
                 })
                

             })            
   }

   displayResults() {
     // storing api call to variable
    var call = this.state.apiCall

  if(call) {
        return (
        <div>
              <h1 id="name"><span className="red">Activity:</span> {call.activity}</h1>
              <br /> <br />
              <h1 className="info"><span className="red">Type:</span> {call.type}</h1> 
          </div>
          )
     } 
    else {
      return <p></p>
    }
  }

  render() {
      return (
      <div className="App">
        <div className="wrapper">
          <header className="logo"><img src="/img/activitydrive-logo.png" className="ad-logo" alt="lightning logo"/>Activity Drive</header>
          <section id="container">
            <About />
            <Button getName = {this.getName}  />
          </section>
          <Result displayResults = {this.displayResults}  />
          <Contact />
          <Footer />
        </div>
      </div>
    );
  }
}
export default App;

